<template id="goods">
  <el-config-provider :locale="locale">
    <!-- 搜索栏 -->
    <div class="search">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
      <el-form-item label="商品名称">
        <el-input v-model="formInline.name" placeholder="请输入" clearable />
      </el-form-item>
      <el-form-item label="商品一级分类">
        <el-select
          v-model="formInline.category1Id"
          placeholder="请选择"
          clearable
          @change="changeCategory1"
        >
          <el-option v-for="item in selectList.category1List" :label="item.name" :value="item.id" :key="item.id"/>
        </el-select>
      </el-form-item>
      <el-form-item label="商品二级分类">
        <el-select
          v-model="formInline.category2Id"
          placeholder="请选择"
          clearable
        >
        <el-option v-for="item in selectList.category2List" :label="item.name" :value="item.id" :key="item.id"/>
        </el-select>
      </el-form-item>
      <el-form-item label="创建时间">
        <el-date-picker
          v-model="formInline.createTimeQuery"
          type="datetimerange"
          range-separator="~"
          start-placeholder="请选择起始时间"
          end-placeholder="请选择终止时间"
          format="YYYY-MM-DD HH:mm:ss"
          value-format="YYYY-MM-DD HH:mm:ss"
          clearable
        />
      </el-form-item>
    </el-form>
    </div>
    <!-- 工具栏 -->
    <div >
      <el-button type="primary" round @click="onSearch">查询</el-button>
      <el-button type="success" round @click="dialogFormVisible = true">新增</el-button>
      <el-button type="danger" round @click="revome">删除</el-button>
    </div>
    <!-- 列表栏 -->
    <div>
      <el-table 
        :data="tableData.goodsList" 
        @selection-change="handleSelectionChange" 
        style="width: 60%">
        <el-table-column type="selection" width="50" /> 
        <el-table-column prop="name" label="商品名称" width="200"/>
        <el-table-column prop="category1Name" label="商品一级分类" width="120" />
        <el-table-column prop="category2Name" label="商品二级分类" width="120" />
        <el-table-column prop="createTime" label="创建时间" width="200" />
        <el-table-column prop="updateTime" label="更新时间" width="200" />
      </el-table>
      <div class="demo-pagination-block">
        <el-pagination
          v-model:current-page="tableData.page"
          v-model:page-size="tableData.limit"
          :page-sizes="[10, 20, 30, 40, 50, 100, 150, 200, 300, 400, 500, 600, 700, 800, 900, 1000]"
          :size="tableData.pages"
          :disabled="disabled"
          :background="background"
          layout="total, sizes, prev, pager, next, jumper"
          :total="tableData.total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
  </el-config-provider>
  <el-button mb-2 @click="toggle">Switch Language/切换语言</el-button>

  <!-- 新增 -->
  <el-dialog v-model="dialogFormVisible" title="新增商品" width="500">
    <el-form :model="fromSave">
      <el-form-item label="商品名称" :label-width="formLabelWidth">
        <el-input v-model="fromSave.name" placeholder="请输入商品名称" autocomplete="off" />
      </el-form-item>
      <el-form-item label="商品一级分类" :label-width="formLabelWidth">
        <el-select v-model="fromSave.category1Id" placeholder="请选择商品一级分类" @change="changeCategory1">
          <el-option v-for="item in selectList.category1List" :label="item.name" :value="item.id" :key="item.id"/>
        </el-select>
      </el-form-item>
      <el-form-item label="商品二级分类" :label-width="formLabelWidth">
        <el-select v-model="fromSave.category2Id" placeholder="请选择商品二级分类">
          <el-option v-for="item in selectList.category2List" :label="item.name" :value="item.id" :key="item.id"/>
        </el-select>
      </el-form-item>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取消</el-button>
        <el-button type="primary" @click="submitSave">
          确认
        </el-button>
      </div>
    </template>
  </el-dialog>
  <router-view></router-view>
</template>

<script setup>
  import { reactive, onMounted, computed, ref } from 'vue'
  import { useRouter } from 'vue-router';
  import axios from '../axios';
  import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
  import en from 'element-plus/dist/locale/en.mjs'
  import { ElNotification } from 'element-plus'

  const router = useRouter();

  const selectedRows = ref([]);

  const handleSelectionChange = (selection) => {
    selectedRows.value = selection;
  };
  
  const formInline = reactive({
    name: '',
    createTimeQuery: [],
    category1Id: '',
    category2Id: '',
  })

  const selectList = reactive({
    category1List: [
      
    ],
    category2List: [
      
    ],
  })

  const tableData = reactive({
    page:1,
    limit:10,
    total:0,
    pages:0,
    goodsList:[

    ]
  })

  const checkboxChange = () => {
    console.log(111);
  }

  const onSearch = async () => {
    const { data } = await axios.post(`http://127.0.0.1:8080/goods/rest/queryGoodsListPage?page=${tableData.page}&limit=${tableData.limit}`,formInline)
      tableData.goodsList = data.records
      tableData.total = data.total
  }

  const handleSizeChange = () => {
    console.log(tableData)
    onSearch()
  }

  const handleCurrentChange = () => {
    console.log(tableData)
    onSearch()
  }

  const language = ref('zh-cn')
  const locale = computed(() => (language.value === 'zh-cn' ? zhCn : en))

  const toggle = () => {
    language.value = language.value === 'zh-cn' ? 'en' : 'zh-cn'
  }

  const dialogFormVisible = ref(false)
  const formLabelWidth = '140px'

  const fromSave = reactive({
    name: '',
    category1Id: '',
    category2Id: '',
  })

  const changeCategory1 = async (value) => {
    formInline.category2Id = '';
    fromSave.category2Id = '';
    const { data } = await axios.get(`http://127.0.0.1:8080/goodsCategory/rest/findChildrenCategory?parentId=${value}`)
    selectList.category2List = data
  }

  const submitSave = async () => {
    const { data } = await axios.post('http://127.0.0.1:8080/goods/rest/insertGoods',fromSave,{
        headers: {
          'Content-Type': 'application/json', // 显式设置请求头
        }
      })
      dialogFormVisible.value = false;
      open2(fromSave.name);
      onSearch();
      fromSave.name = '';
      fromSave.category1Id = '';
      fromSave.category2Id = '';
  }

  const open2 = (name) => {
    ElNotification({
      title: '新增成功',
      message: `新的商品【${name}】已被您新增成功`,
      duration: 0,
    })
  }

  const revome = async () => {
    console.log()
  }

  const getCategory1List = async () => {
      const { data } = await axios.get('http://127.0.0.1:8080/goodsCategory/rest/findParentCategory')
      selectList.category1List = data;
  }

  onMounted(async () => {
    onSearch();
    getCategory1List();
  })
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .demo-form-inline .el-input {
    --el-input-width: 250px;
  }

  .demo-form-inline .el-select {
    --el-select-width: 220px;
  }
  .demo-form-inline {
    display: flex;
    flex-wrap: wrap;
  }
  .demo-pagination-block + .demo-pagination-block {
    margin-top: 10px;
  }
  .demo-pagination-block .demonstration {
    margin-bottom: 16px;
  }
</style>
