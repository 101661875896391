<template>
    <div> 
        <el-card class="box-card">
            <template #header>
                <div class="card-header">
                <span>登录</span>
                </div>
            </template>
            <el-form :model="fromLogin" label-width="80px">
                <el-form-item label="账号">
                <el-input v-model="fromLogin.accountNo"></el-input>
                </el-form-item>
                <el-form-item label="密码">
                <el-input type="password" v-model="fromLogin.password" show-password></el-input>
                </el-form-item>
                <el-form-item>
                <el-button type="primary" @click="handleLogin">登录</el-button>
                <el-button type="danger" @click="handleRegister">注册</el-button>
                </el-form-item>
            </el-form>
        </el-card>
    </div>
    <router-view></router-view>
</template>

<script setup>
   import { useRouter } from 'vue-router';
   import { reactive } from 'vue'; 
   import axios from '../axios';
   import { ElNotification } from 'element-plus'

   const router = useRouter();
   const fromLogin = reactive({
    accountNo: '',
    password: '',
   })

   const handleLogin = async () => {
    const res = await axios.post(`http://127.0.0.1:8080/login/toLogin`,fromLogin)
    console.log(res)
    if(!res.success){
        open('登录失败', res.message)
    }else{
        open('登录成功', `欢迎${fromLogin.accountNo}`)
        localStorage.setItem('token',res.data.token)
        router.push('/')
    }
   }

   const handleRegister = async () => {
    const res = await axios.post(`http://127.0.0.1:8080/login/toRegister`,fromLogin)
    console.log(res)
    if(!res.success){
        open('注册失败', res.message)
    }else{
        open('注册成功', `欢迎${fromLogin.accountNo}`)
        localStorage.setItem('token',res.data.token)
        router.push('/')
    }
   }

   const open = (title, message) => {
    ElNotification({
      title,
      message,
      duration: 0,
    })
   }
</script>

<style scoped>

    .register-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        background-color: #f0f2f5;
    }
    
    .box-card {
        width: 400px;
    }
    
    .card-header {
        text-align: center;
        font-size: 20px;
        font-weight: bold;
    }

  </style>