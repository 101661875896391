<template id="app">
  <el-button type="success" @click="gotoGoods">前往商品管理页面</el-button>
  <el-button type="danger" @click="gotoCategory">前往商品分类管理页面</el-button>
  <el-button type="warning" @click="exitLogin">退出登录</el-button>
  <div class="footer">
    <el-text v-if="isText" class="mx-1" type="success">小王开发的新页面(vue3)</el-text>
  </div>
  <div>
    <router-view></router-view>
  </div>
</template>

<script setup>
  
  import { useRouter } from 'vue-router';
  import { ref, onMounted } from 'vue';
  import { ElNotification } from 'element-plus'


  const router = useRouter();

  const exitLogin = () => {
    const token = localStorage.getItem('token');
    if(!token){
      open('退出失败', '本来就没登录');
    }else{
      localStorage.removeItem('token');
      open('退出成功', '已经退出了登录');
      router.push('/')
    }
  }

  const open = (title, message) => {
    ElNotification({
      title,
      message,
      duration: 0,
    })
  }

  const isLogin = () => {
    const token = localStorage.getItem("token")
    if(!token){
      open('未登录', '请先登录');
      isText.value = false;
      router.push("/login");
      return false;
    }
    return true
  }
  

  const isText = ref(true)

  const gotoGoods = () => {
    if(isLogin()){
      isText.value = false;
      router.push("/goods");
    }
  }

  const gotoCategory = () => {
    if(isLogin()){
      isText.value = false;
      router.push("/category");
    }
  }

  onMounted(async () => {
    console.log("onMounted~")
    isText.value = true
  })

</script>


<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
.footer {
  margin-top: 10px
}
</style>
