// src/api/index.js
import axios from 'axios';
import { useRouter } from 'vue-router';

const router = useRouter()

// 创建一个 axios 实例
const service = axios.create({
  baseURL: "http://127.0.0.1:8080", // 设置 API 的 base_url
  timeout: 3000 // 请求超时时间
});

// 请求拦截器
service.interceptors.request.use(
  config => {
    // 在发送请求之前做些什么，例如添加 token
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = token;
    }
    return config;
  },
  error => {
    // 对请求错误做些什么
    console.error(error);
    return Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  response => {
    if(response.data.code === 800){
      router.push("/login");
    }
    // 对响应数据做点什么
    return response.data;
  },
  error => {

    // 对响应错误做点什么
    console.error(error);
    return Promise.reject(error);
  }
);

export default service;
