<template id="category">
  <el-config-provider :locale="locale">
    <!-- 搜索栏 -->
    <div class="search">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
      <el-form-item label="分类名称">
        <el-input v-model="formInline.name" placeholder="请输入" clearable />
      </el-form-item>
      <el-form-item label="创建时间">
        <el-date-picker
          v-model="formInline.createTimeQuery"
          type="datetimerange"
          range-separator="~"
          start-placeholder="请选择起始时间"
          end-placeholder="请选择终止时间"
          format="YYYY-MM-DD HH:mm:ss"
          value-format="YYYY-MM-DD HH:mm:ss"
          clearable
        />
      </el-form-item>
    </el-form>
    </div>
    <!-- 工具栏 -->
    <div >
      <el-button type="primary" round @click="onSearch">查询</el-button>
      <el-button type="success" round @click="dialogFormVisible = true">新增</el-button>
      <el-button type="danger" round @click="revome">删除</el-button>
    </div>
    <!-- 列表栏 -->
    <div>
      <el-table :data="tableData.categoryList" style="width: 50%">
        <el-table-column type="selection" width="50" :selectable="selectable" :select="checkboxChange"/> 
        <el-table-column prop="name" label="分类名称" width="200"/>
        <el-table-column label="图片" width="120">
            <template #default="scope">
                <el-image :src="scope.row.imageUrl" style="width: 50px; height: auto;"/>
            </template>
            </el-table-column>
        <el-table-column prop="createTime" label="创建时间" width="200" />
        <el-table-column prop="updateTime" label="更新时间" width="200" />
      </el-table>
      <div class="demo-pagination-block">
        <el-pagination
          v-model:current-page="tableData.page"
          v-model:page-size="tableData.limit"
          :page-sizes="[10, 20, 30, 40, 50, 100, 150, 200, 300, 400, 500, 600, 700, 800, 900, 1000]"
          :size="tableData.pages"
          :disabled="disabled"
          :background="background"
          layout="total, sizes, prev, pager, next, jumper"
          :total="tableData.total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
  </el-config-provider>
  <el-button mb-2 @click="toggle">Switch Language/切换语言</el-button>

  <!-- 新增 -->
  <el-dialog v-model="dialogFormVisible" title="新增商品" width="500">
    <el-form :model="fromSave">
      <el-form-item label="分类名称" :label-width="formLabelWidth">
        <el-input v-model="fromSave.name" placeholder="请输入商品名称" autocomplete="off" />
      </el-form-item>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取消</el-button>
        <el-button type="primary" @click="submitSave">
          确认
        </el-button>
      </div>
    </template>
  </el-dialog>
<router-view></router-view>
</template>

<script setup>
  import { reactive, onMounted, computed, ref } from 'vue'
  import { useRouter } from 'vue-router';
  import axios from 'axios';
  import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
  import en from 'element-plus/dist/locale/en.mjs'
  import { ElNotification } from 'element-plus'

  const router = useRouter();
  
  const formInline = reactive({
    name: '',
    createTimeQuery: [],
    category1Id: '',
    category2Id: '',
  })

  const selectable = (row) => {
    return true
  }

  const selectList = reactive({
    category1List: [
      {
        id:'267066519354216448',
        name:'爱礼精选'
      },
      {
        id:'267066519354216453',
        name:'鲜花玫瑰'
      },
      {
        id:'267066519354216456',
        name:'永生玫瑰'
      }
    ],
    category2List: [
      {
        id:'267072445574418433',
        name:'真情告白'
      },
      {
        id:'267072445574418434',
        name:'浪漫求婚'
      },
      {
        id:'267072445574418435',
        name:'珍贵纪念'
      }
    ],
  })

  const tableData = reactive({
    page:1,
    limit:10,
    total:0,
    pages:0,
    categoryList:[

    ]
  })

  const checkboxChange = () => {
    console.log(111);
  }

  const onSearch = async () => {
    const { data } = await axios.post(`http://127.0.0.1:8080/goodsCategory/rest/queryGoodsCategoryListPage?page=${tableData.page}&limit=${tableData.limit}`,formInline,{
        headers: {
          'Content-Type': 'application/json', // 显式设置请求头
        }
      })
      tableData.categoryList = data.data.records
      tableData.total = data.data.total
  }

  const handleSizeChange = () => {
    console.log(tableData)
    onSearch()
  }

  const handleCurrentChange = () => {
    console.log(tableData)
    onSearch()
  }

  const language = ref('zh-cn')
  const locale = computed(() => (language.value === 'zh-cn' ? zhCn : en))

  const toggle = () => {
    language.value = language.value === 'zh-cn' ? 'en' : 'zh-cn'
  }

  const dialogFormVisible = ref(false)
  const formLabelWidth = '140px'

  const fromSave = reactive({
    name: '',
    category1Id: '',
    category2Id: '',
  })

  const submitSave = async () => {
    const { data } = await axios.post('http://127.0.0.1:8080/goodsCategory/rest/insertGoodsCategory',fromSave,{
        headers: {
          'Content-Type': 'application/json', // 显式设置请求头
        }
      })
      dialogFormVisible.value = false;
      open2(fromSave.name);
      onSearch();
      fromSave.name = '';
      fromSave.category1Id = '';
      fromSave.category2Id = '';
  }

  const open2 = (name) => {
    ElNotification({
      title: '新增成功',
      message: `新的商品【${name}】已被您新增成功`,
      duration: 0,
    })
  }

  const revome = async () => {
    console.log(selectable)
  }

  onMounted(async () => {
    onSearch()
  })
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .demo-form-inline .el-input {
    --el-input-width: 250px;
  }

  .demo-form-inline .el-select {
    --el-select-width: 220px;
  }
  .demo-form-inline {
    display: flex;
    flex-wrap: wrap;
  }
  .demo-pagination-block + .demo-pagination-block {
    margin-top: 10px;
  }
  .demo-pagination-block .demonstration {
    margin-bottom: 16px;
  }
</style>
