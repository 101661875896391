import { createRouter, createWebHistory } from 'vue-router';
import App from './App';
import Goods from './components/Goods.vue';
import Category from './components/Category.vue';
import Login from './components/Login.vue';

const routes = [
    {
        path: '/app',
        name: 'App',
        component: App
    },
    {
        path: '/goods',
        name: 'Goods',
        component: Goods
    },
    {
        path: '/category',
        name: 'Category',
        component: Category
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    }
  ];

  
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
  });
  
  export default router;
  